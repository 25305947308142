[
  {
    "stoic": "Marcus Aurelius",
    "bio": "",
    "quotes": [
      "The best answer to anger is silence.",
      "The more we value things outside our control, the less control we have.",
      "How ridiculous and how strange to be surprised at anything which happens in life.",
      "You have power over your mind – not outside events. Realize this, and you will find strength.",
      "The best revenge is not to be like your enemy.",
      "Accept the things to which fate binds you, and love the people with whom fate brings you together, but do so with all your heart.",
      "Confine yourself to the present.",
      "When you arise in the morning, think of what a precious privilege it is to be alive – to breathe, to think, to enjoy, to love.",
      "You can commit injustice by doing nothing.",
      "Waste no more time arguing about what a good man should be. Be one.",
      "Each day provides its own gifts.",
      "Give yourself a gift, the present moment.",
      "The only wealth which you will keep forever is the wealth you have given away.",
      "Conceal a flaw, and the world will imagine the worst.",
      "Life is neither good or evil, but only a place for good and evil.",
      "I cannot escape death, but at least I can escape the fear of it.",
      "How trivial the things we want so passionately are.",
      "Settle on the type of person you want to be and stick to it, whether alone or in company.",
      "Consider at what price you sell your integrity, but please, for God’s sake, don’t sell it cheap.",
      "It never ceases to amaze me: we all love ourselves more than other people, but care more about their opinion than our own.",
      "If it is not right, do not do it, if it is not true, do not say it.",
      "The best revenge is not to be like your enemy.",
      "External things are not the problem. It’s your assessment of them. Which you can erase right now.",
      "Choose not to be harmed — and you won’t feel harmed. Don’t feel harmed — and you haven’t been."
    ]
  },
  {
    "stoic": "Seneca",
    "bio": "",
    "quotes": [
      "Luck is what happens when preparation meets opportunity.",
      "We suffer more often in imagination than in reality.",
      "Ignorance is the cause of fear.",
      "While we wait for life, life passes.",
      "Life is long, if you know how to use it.",
      "Hurry up and live.",
      "Cease to hope and you will cease to fear.",
      "Wealth is the slave of a wise man and the master of a fool.",
      "Only time can heal what reason cannot.",
      "While we are postponing, life speeds by.",
      "Life, if well lived, is long enough.",
      "Hang on to your youthful enthusiasms, you will be able to use them better when you are older.",
      "He who is brave is free.",
      "It is a rough road that leads to the heights of greatness.",
      "It is more civilized to make fun of life than to bewail it.",
      "Difficulty comes from our lack of confidence.",
      "Life is very short and anxious for those who forget the past, neglect the present, and fear the future.",
      "What really ruins our character is the fact that none of us looks back over his life.",
      "Religion is regarded by the common people as true, by the wise as false, and by rulers as useful.",
      "When a person spends all his time in foreign travel, he ends by having many acquaintances, but no friends.",
      "For many men, the acquisition of wealth does not end their troubles, it only changes them.",
      "The greatest remedy for anger is delay.",
      "An age builds up cities, an hour destroys them. In a moment the ashes are made, but a forest is a long time growing.",
      "There is no person so severely punished, as those who subject themselves to the whip of their own remorse.",
      "How does it help…to make troubles heavier by bemoaning them?",
      "You are your choices."
    ]
  },
  {
    "stoic": "Epictetus",
    "bio": "",
    "quotes": [
      "We have two ears and one mouth so that we can listen twice as much as we speak.",
      "No man is free who is not master of himself.",
      "Men are disturbed not by things, but by the view which they take of them.",
      "It is difficulties that show what men are.",
      "Make the best use of what is in your power, and take the rest as it happens.",
      "A ship should not ride on a single anchor, nor life on a single hope.",
      "No great thing is created suddenly.",
      "Know, first, who you are, and then adorn yourself accordingly.",
      "It is the nature of the wise to resist pleasures, but the foolish to be a slave to them.",
      "First learn the meaning of what you say, and then speak.",
      "If you want something good, get it yourself."
    ]
  },
  {
    "stoic": "Zeno",
    "bio": "",
    "quotes": [
      "No loss should be more regrettable to us than losing our time, for it’s irretrievable.",
      "Extravagance is its own destroyer.",
      "Man conquers the world by conquering himself.",
      "Better to trip with the feet than with the tongue.",
      "A bad feeling is a commotion of the mind repugnant to reason, and against nature."
    ]
  },
  {
    "stoic": "Viktor Frankl",
    "bio": "",
    "quotes": [
      "Our greatest freedom is the freedom to choose our attitude.",
      "When we are no longer able to change a situation, we are challenged to change ourselves."
    ]
  },
  {
    "stoic": "William James",
    "bio": "",
    "quotes": [
      "Thoughts become perception, perception becomes reality. Alter your thoughts, alter your reality.",
      "The greatest weapon against stress is our ability to choose one thought over another.",
      "Believe that life is worth living and your belief will help create the fact.",
      "If you can change your mind, you can change your life."
    ]
  }
]
